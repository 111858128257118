function App() {
  return (
    <div className="App min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <header className="App-header text-center">
        <p className="text-xl font-semibold">
          Welcome to Western Motors site. Will be under construction.
        </p>
      </header>
    </div>
  );
}

export default App;
